const tagManagerName = 'gtag';
let isAlreadyLogged = false;
/**
 * Wrapper function to send data to Google (page views, events, enhanced ecommerce data, etc.).
 *
 * @param {*} args
 */
export default (...args: any[]) => {
	if (typeof window === 'undefined' || typeof window[tagManagerName] !== 'function') {
		if (!isAlreadyLogged) {
			console.warn('Unable to add data to the data layer, the Global Site Tag is not setup.');
			isAlreadyLogged = true;
		}
		return;
	}
	window[tagManagerName](...args);
};
