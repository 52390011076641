import { RocEventType, RocEvent, ExtractEventParameters } from 'shared/events';

/**
 * Helper function for handling ROC events from non React functions.
 * @param eventType The type of event to handle
 * @param eventHandler The event handler
 * @returns The callback function to remove this handler
 */
export default function addRocEventHandler<T extends RocEventType>(
	eventType: T,
	eventHandler: (data: ExtractEventParameters<RocEvent, T>) => void,
): () => void {
	const handler = (evt: CustomEvent): void => {
		eventHandler(evt.detail);
	};

	document.addEventListener(eventType, handler);

	return () => document.removeEventListener(eventType, handler);
}
